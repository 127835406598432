import styled from '@emotion/styled';
import React from 'react';

import { color_main } from '../../theme/styleGuide';

export const PrimaryActionButton = ({
  children,
  onClick,
  isValid = false,
  isWhite = false,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  isValid?: boolean;
  isWhite?: boolean;
}) => {
  return (
    <Submit
      isWhite={isWhite}
      isValid={isValid}
      onClick={() => {
        if (isValid) {
          onClick && onClick();
        }
      }}
    >
      {children}
    </Submit>
  );
};

export default PrimaryActionButton;

const Submit = styled.div<{ isWhite: boolean; isValid: boolean }>`
  font-size: 15px;

  padding: 0 16px;
  width: 100%;
  min-height: 50px;
  min-width: 80px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid;
  border-color: ${({ isValid }) => (isValid ? color_main : '#CCCCCC')};

  color: ${({ isWhite }) => (isWhite ? color_main : 'white')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: ${({ isWhite, isValid }) =>
    isValid ? (isWhite ? 'white' : color_main) : '#CCCCCC'};

  &.disabled {
    opacity: 0.3;
  }

  &:hover {
    cursor: pointer;
  }
`;
