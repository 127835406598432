import { css } from '@emotion/react';

export const shadow_dreamy = css`
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.07),
    0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07),
    0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07),
    0 32px 64px rgba(0, 0, 0, 0.07);
`;

export const breakPointSp = '520px';
export const breakPointTablet = '960px';

export const color_green = '#004647';
export const color_main = '#204E73';
