import styled from '@emotion/styled';

const Spacer = styled.div<{
  width?: number | string;
  height?: number | string;
}>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  display: inline-block;
  flex-shrink: 0;
`;

export default Spacer;
